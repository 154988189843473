import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Home, Prysm, About } from './Pages';
import { Header, Modal } from './components';
import { routes } from './routes';

const AppContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  ${({ theme }) => css`
    ${theme.media.xs} {
      padding-top: 69px;
    }
  `}
`;

const Main = styled.main`
  width: 100%;
  position: relative;
  min-height: 100%;
`;

export const App = () => {
  const [modal, setModal] = useState(false);

  return (
    <AppContainer>
      <Header setModal={setModal} />
      <Main>
        <Switch>
          <Redirect exact from="/" to={routes.home} />
          <Route
            path={routes.home}
            component={() => <Home setModal={setModal} />}
          />
          <Route
            path={routes.prysm}
            component={() => <Prysm setModal={setModal} />}
          />
          <Route
            path={routes.about}
            component={() => <About setModal={setModal} />}
          />
        </Switch>
      </Main>
      <Modal modal={modal} setModal={setModal} />
    </AppContainer>
  );
};
